import Organization, { OrganizationProps } from './Organization';
import { OrganizationInterface } from './Organization';

export interface OrganizationsInterface {
    organizations: OrganizationInterface[];
    total: number;
}

export type OrganizationsProps = {
    orgs: OrganizationProps[];
    total: number;
};

export default class Organizations implements OrganizationsInterface {
    readonly organizations: Organization[];
    readonly total: number;

    constructor(props: OrganizationsProps) {
        this.organizations = props.orgs.map((org) => new Organization(org));
        this.total = props.total;
    }
}
