import { useEffect, useState } from 'react';
import Flag from 'react-flagpack';
import 'react-flagpack/dist/style.css';
import styles from './AppFlagIcon.module.less';

interface AppFlagIconProps {
    code: string;
}

const AppFlagIcon = ({ code }: AppFlagIconProps) => {
    const [flagCode, setFlagCode] = useState<string>(code?.toUpperCase());
    useEffect(() => {
        if (code === 'gb' || code === 'uk') {
            setFlagCode('GB-UKM');
        } else {
            setFlagCode(code?.toUpperCase());
        }
    }, [code]);

    return (
        <div className={styles.AppFlagIcon}>
            {flagCode && (
                <Flag
                    size="m"
                    code={flagCode}
                    hasBorderRadius={true}
                    hasDropShadow={true}
                />
            )}
        </div>
    );
};

export default AppFlagIcon;
