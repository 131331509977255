import ApiService from './ApiService';
import { API_AUTH, ORGANIZATIONS } from '../core/CcxEnv';
import Organizations from '../types/Organizations';

// constants
const API_URL = API_AUTH;

export default class OrganizationsService extends ApiService {
    /**
     * Get list of all firewall rules.
     * @param {string} uuid
     */
    static async getAllOrganizations(): Promise<Organizations> {
        const response = await this.request('GET', ORGANIZATIONS);
        return new Organizations(response);
    }

    static async addNewOrganization(org: {
        id: any;
        name: string;
    }): Promise<any[]> {
        const requestData = {
            data: {
                ...org,
            },
        };
        const response = await this.request('POST', ORGANIZATIONS, requestData);
        return response;
    }

    static async updateOrganization(org: {
        id: string | number;
        name: string;
    }): Promise<any[]> {
        const requestData = {
            data: {
                ...org,
            },
        };
        const response = await this.request(
            'PATCH',
            `${ORGANIZATIONS}/${org.id}`,
            requestData
        );
        return response;
    }

    static async deleteOrganization(org: {
        id: string | number;
    }): Promise<any[]> {
        const response = await this.request(
            'DELETE',
            `${ORGANIZATIONS}/${org.id}`
        );
        return response;
    }

    /**
     * Add a Firewall rule
     * @param {AddRuleProps} props
     */

    protected static getApiUrl() {
        return API_URL;
    }
}
