import { Tabs } from 'antd';
import { ReactElement, Suspense } from 'react';
import CcxComponentProps from '../../core/CcxComponent';
import LazyLoader from '../LazyLoader';
import styles from './Organization.module.less';
import OrganizationInfo from './organizationInfo/OrganizationInfo';

interface Props extends CcxComponentProps {}

function Organization({ testId = 'Organization' }: Props): ReactElement {
    return (
        <section className={styles.Organization} data-testid={testId}>
            <h1 data-testid={`${testId}Title`}>
                <strong>Organizations</strong>
            </h1>

            <Tabs
                className={styles.OrganizationTabs}
                data-testid={`${testId}Tabs`}
                type="card"
                destroyInactiveTabPane={true}
            >
                <Tabs.TabPane
                    data-testid={`${testId}TabsOrganizationInfo`}
                    tab="Details"
                    key="organization-info"
                >
                    <Suspense fallback={<LazyLoader />}>
                        <OrganizationInfo />
                    </Suspense>
                </Tabs.TabPane>
            </Tabs>
        </section>
    );
}

export default Organization;
