const ADMIN = 'admin';
const ORGANIZATION = 'org';
export type UserScopeProps = {
    id: string;
    type: string;
    role: string;
    name: string;
};

export type UserScopeInterface = {
    id: string;
    type: string;
    role: string;
    name: string;
};

export default class UserScope {
    readonly id: string;
    readonly type: string;
    readonly role: string;
    readonly name: string;

    constructor(props: UserScopeProps) {
        this.id = props.id;
        this.type = props.type;
        this.role = props.role;
        this.name = props.name;
    }

    isOrganization(): boolean {
        return this.role === ORGANIZATION;
    }
}
