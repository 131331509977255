import ApiService from './ApiService';
import { API_DEPLOYMENT_V2 } from '../core/CcxEnv';
import DbService, { DbServiceClassProps } from '../types/DbService';
import Jobs from '../types/Jobs';

// constants
const API_URL = API_DEPLOYMENT_V2;

export interface Services {
    dbServices: DbService[];
}

export default class ServiceService extends ApiService {
    /**
     * Get services for a data store
     * @param {string} dataStoreUuid
     */
    static async getAllServices(dataStoreUuid: string): Promise<Services> {
        const response = await this.request(
            'GET',
            `data-stores/${dataStoreUuid}/nodes`
        );
        return {
            dbServices:
                response?.database_nodes?.map(
                    (s: DbServiceClassProps) => new DbService(s)
                ) ?? [],
        };
    }

    /**
     * Get jobs for a data store
     * @param {string} dataStoreUuid
     */
    static async getAllJobs(
        dataStoreUuid: string,
        limit: number = 50,
        offset: number = 0
    ): Promise<any> {
        const response = await this.request(
            'GET',
            `data-stores/${dataStoreUuid}/jobs?limit=${limit}&offset=${offset}`
        );
        return new Jobs(response);
    }

    /**
     * Get database services for a data store
     * @param {string} dataStoreUuid
     */
    static async getDbServices(dataStoreUuid: string): Promise<DbService[]> {
        const response = await this.request(
            'GET',
            `deployment/${dataStoreUuid}`
        );
        return response?.database_nodes?.map(
            (s: DbServiceClassProps) => new DbService(s)
        );
    }

    /**
     * Get load balancer services for a data store
     * @param {string} dataStoreUuid
     */

    protected static getApiUrl() {
        return API_URL;
    }
}
