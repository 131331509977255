import { Modal, Form, Input, Button, Row, Col } from 'antd';
import styles from './OrganizationModal.module.less';
import { useEffect, useState } from 'react';
import Organization from '../../../types/Organization';

interface OrganizationModalProps {
    visible: boolean;
    onClose: () => void;
    onSubmit: (values: Organization) => Promise<void>;
    record: Organization;
    isEditing: boolean;
    testId?: string;
    isLoading?: boolean;
}

const OrganizationModal = ({
    record,
    visible,
    onClose,
    onSubmit,
    isEditing,
    isLoading,
    testId = 'OrganizationModal',
}: OrganizationModalProps) => {
    const [form] = Form.useForm();
    const [isValueUpdated, setIsValueUpdated] = useState(true);
    const onFinish = async (values: any) => {
        try {
            await form.validateFields();
            onSubmit(values);
        } catch (error) {
            console.error('Failed to submit:', error);
        }
    };

    useEffect(() => {
        if (record) {
            form.setFieldsValue({
                name: record.name,
            });
        }
    }, [record, form]);

    const onCancel = () => {
        form.resetFields();
        onClose();
    };

    return (
        <>
            <Modal
                title={
                    isEditing
                        ? 'Update Organization'
                        : 'Create New Organization'
                }
                visible={visible}
                onCancel={onCancel}
                footer={
                    <Row justify="end">
                        <Col>
                            <Button
                                data-testid={`${testId}CancelButton`}
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                data-testid={`${testId}SubmitButton`}
                                onClick={() => onFinish(form.getFieldsValue())}
                                type="primary"
                                loading={isLoading}
                                disabled={isValueUpdated}
                            >
                                {isEditing ? 'Update' : 'Create'}
                            </Button>
                        </Col>
                    </Row>
                }
            >
                <Form
                    form={form}
                    layout="vertical"
                    onValuesChange={() => setIsValueUpdated(false)}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the organization name!',
                            },
                        ]}
                    >
                        <Input
                            className={styles.OrganizationModalInput}
                            placeholder="Organization Name"
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default OrganizationModal;
