// import ClusterInfo from './ClusterInfo';
import FriendlyDateTime from './FriendlyDateTime';
import DbNode from './DbNode';
import CloudInstance, { CloudInstanceClassProps } from './CloudInstance';
import { sortNodesByRole } from '../core/helpers';

export const DB_VENDOR_MARIADB = 'mariadb';
export const DB_VENDOR_POSTGRESQL = 'pgsql';
export const DB_VENDOR_UNKNOWN = '';

export const DB_VENDOR_MARIADB_PRETTY = 'MariaDB';
export const DB_VENDOR_POSTGRESQL_PRETTY = 'PostgreSQL';

const MARIADB_ICON = 'vendor/mariadb';
const POSTGRESQL_ICON = 'vendor/postgresql';
const UNKNOWN_ICON = '';

export type DeploymentClassProps = {
    uuid: string;
    database_nodes: any[];
};

export interface DeploymentInterface {
    dataStoreUuid: string;
    dbNodes: DbNode[];
}

export default class Deployment implements DeploymentInterface {
    readonly dataStoreUuid: string;
    readonly dbNodes: DbNode[];

    constructor(props: DeploymentClassProps) {
        this.dataStoreUuid = props.uuid;
        this.dbNodes = props?.database_nodes?.map(
            (n: CloudInstanceClassProps) => new DbNode(n)
        );
    }

    /**
     * Return array of galera hosts
     */
    getDbNodes(): DbNode[] {
        return sortNodesByRole(this.dbNodes);
    }

    /**
     * Return array of proxysql hosts
     */

    // @TODO: DEPRECATE
    isClusterPostgreSql(): boolean {
        return this.isPostgreSql(this.dbNodes[0]);
    }

    getUniqueHostsByNodeType(short: boolean = false) {
        const dbHosts = this.dbNodes.map((n: DbNode) => n.getFqdn(short));

        return {
            dbHosts: dbHosts.filter(
                (v: any, i: number) => dbHosts.indexOf(v) === i
            ),
        };
    }

    getHosts() {
        return {
            dbHosts: this.dbNodes,
        };
    }

    protected getHostDetails(host: CloudInstance) {
        // "hoststatus" possible values are 'CmonHostUnknown', 'CmonHostOnline', 'CmonHostOffLine',
        // 'CmonHostFailed', 'CmonHostRecovery', 'CmonHostShutDown'.
        // The type is string, the default value is "CmonHostUnknown".
        // @TODO: Refactor this to return a proper CloudInstance object
        return {
            ...host,
            status: this.getStatusText(host.hostStatus),
            isOk: () => this.isOk(host),
            isError: () => this.isError(host),
            lastSeen: new FriendlyDateTime({
                date: host.lastSeenAt,
            }).getFriendlyDate(),
            lastSeenFull: new FriendlyDateTime({
                date: host.lastSeenAt,
            }).getFullDate(),
            key: host.uniqueId,
            icon: '',
            nodeType: this.getNodeType(host),
            onlineFor: new FriendlyDateTime({
                date: host.serviceStartedAt,
            }).getFriendlyDate(),
            instance: `${host.publicIp || host.privateIp}:${host.port}`,
        };
    }

    protected getStatusText(hostStatus: string) {
        switch (hostStatus) {
            case 'CmonHostOnline':
                return 'Online';
            case 'CmonHostOffLine':
                return 'Offline';
            case 'CmonHostShutDown':
                return 'ShutDown';
            case 'CmonHostFailed':
                return 'Failed';
            case 'CmonHostRecovery':
                return 'Recovering';
            case 'CmonHostUnknown':
            default:
                return 'Unknown';
        }
    }

    protected isOk(host: any): boolean {
        return host.hostStatus === 'CmonHostOnline';
    }

    protected isError(host: any): boolean {
        return (
            host.hostStatus === 'CmonHostFailed' ||
            host.hostStatus === 'CmonHostShutDown' ||
            host.hostStatus === 'CmonHostOffLine'
        );
    }

    protected getNodeType(host: any): string {
        if (this.isGalera(host)) {
            return 'Galera';
        } else if (this.isProxySql(host)) {
            return 'ProxySQL';
        } else if (this.isHaProxy(host)) {
            return 'HAProxy';
        } else if (this.isMaster(host)) {
            return 'Master';
        } else if (this.isSlave(host)) {
            return 'Slave';
        } else {
            // load balancers wil
            return '';
        }
    }

    protected isGalera(host: any): boolean {
        return host.nodeType === 'galera';
    }

    protected isPostgreSql(host: any): boolean {
        return host.nodeType === 'postgres';
    }

    protected isProxySql(host: any): boolean {
        return host.nodeType === 'proxysql';
    }

    protected isHaProxy(host: any): boolean {
        return host.nodeType === 'haproxy';
    }

    protected isMaster(host: any): boolean {
        return host.role === 'master';
    }

    protected isSlave(host: any): boolean {
        return host.role === 'slave';
    }

    getHostName(uuid: string): string {
        const dbNode = this.getDbNodes().find((n) => n.hostUuid === uuid);
        return dbNode ? dbNode.getFqdnWithRole(true) : '';
    }
}
