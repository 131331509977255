import { Alert, Col, Form, Row, Select } from 'antd';
import InstanceSize from '../../../types/InstanceSize';
import InstanceVolumeTypeForm from './formParts/InstanceVolumeTypeForm';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import DeploymentOptions from '../../../types/DeploymentOptions';
import CloudProvider from '../../../types/CloudProvider';
import { FormInstance } from 'antd/lib/form';
import InstanceVolumeSize from '../../../types/InstanceVolumeSize';
import InstanceVolumeType from '../../../types/InstanceVolumeType';
import InstanceVolumeIopsValue from '../../../types/InstanceVolumeIopsValue';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import DatabaseVendor from '../../../types/DatabaseVendor';
import { useAppSelector } from '../../../redux/hooks';
import styles from './InstanceSizeForm.module.less';

export type Props = {
    testId: string;
    selectedTech?: DatabaseVendor;
    handleInstanceSizeChange: Function;
    deploymentOptions?: DeploymentOptions;
    cloudProvider: CloudProvider | undefined;
    handleVolumeTypeChange: Function;
    form: FormInstance;
    volumeSize: InstanceVolumeSize | undefined;
    volumeType: InstanceVolumeType | undefined;
    volumeIops: InstanceVolumeIopsValue | undefined;
    enteredVolumeSize: number | undefined;
    setEnteredVolumeSize: Function;
    setEnteredIOPS: Function;
    instance: InstanceSize | undefined;
    handleVolumeIopsChange: Function;
    setVolumeSize: React.Dispatch<
        React.SetStateAction<InstanceVolumeSize | undefined>
    >;
};

export type StorageItem = {
    label?: string;
    info?: string;
    code?: string;
    volumeTypes?: InstanceVolumeType[];
};

export default function InstanceSizeForm({
    testId,
    selectedTech,
    handleInstanceSizeChange,
    deploymentOptions,
    cloudProvider,
    form,
    handleVolumeTypeChange,
    volumeIops,
    volumeSize,
    volumeType,
    enteredVolumeSize,
    setEnteredVolumeSize,
    setEnteredIOPS,
    setVolumeSize,
    instance,
    handleVolumeIopsChange,
}: Props): ReactElement {
    const { isBackupRestore, restoreDatastore } = useAppSelector(
        (state) => state.restoreBackup
    );
    const onVolumeSizeChange = (value?: number) => {
        setEnteredVolumeSize && setEnteredVolumeSize(value);
    };
    const [storageType, setStorageType] = useState<string>(
        form.getFieldValue('storageType')
    );

    const [storageItems, volumeTypes] = useMemo(() => {
        const volumeTypes =
            deploymentOptions?.getVolumeTypes(cloudProvider) || [];
        const storageItems: StorageItem[] = [];
        const ephemeral = volumeTypes?.find((v) => v.name === 'Ephemeral');
        if (ephemeral) {
            storageItems.push({
                label: 'Volumes',
                code: 'volumes',
                volumeTypes: volumeTypes?.filter((v) => v.name !== 'Ephemeral'),
            });
            storageItems.push({
                label: ephemeral.name,
                info: ephemeral.info,
                code: 'ephemeral',
                volumeTypes: [],
            });
        }

        const localVolumeTypes =
            storageItems.length === 0
                ? volumeTypes
                : storageItems.find((s) => s.code === storageType)?.volumeTypes;

        return [storageItems, localVolumeTypes || []];
    }, [deploymentOptions, cloudProvider, storageType, instance]);

    const onIOPSChange = (value?: number) => {
        setEnteredIOPS && setEnteredIOPS(value);
    };

    useEffect(() => {
        if (
            isBackupRestore &&
            restoreDatastore &&
            deploymentOptions &&
            cloudProvider
        ) {
            let restoreVolumeType = '';
            storageItems.forEach((s: StorageItem) => {
                if (
                    s.volumeTypes?.find(
                        (v) => v.code === restoreDatastore?.volumeType
                    )
                ) {
                    restoreVolumeType = s.code || '';
                }
            });
            const instanceSizes =
                deploymentOptions.getInstanceSizes(cloudProvider);
            const selectedInstanceSize = instanceSizes.find(
                (size) => size.type === restoreDatastore.instanceSize
            );
            const availableVolumeTypes =
                deploymentOptions.getVolumeTypes(cloudProvider);
            const selectedVolumeType = availableVolumeTypes.find(
                (type) => type.code === restoreDatastore.volumeType
            );

            const defaultVolumeSize = deploymentOptions.getVolumeSize(
                cloudProvider,
                selectedVolumeType
            );

            setVolumeSize({
                ...defaultVolumeSize,
                default: restoreDatastore.volumeSize,
            });
            handleInstanceSizeChange({
                target: { value: selectedInstanceSize },
            });

            if (selectedVolumeType) {
                handleVolumeTypeChange({
                    target: { value: selectedVolumeType },
                });
            }

            setEnteredVolumeSize(
                deploymentOptions?.getVolumeSize(
                    cloudProvider,
                    selectedVolumeType
                )
            );
            setStorageType(restoreVolumeType);

            form.setFieldsValue({
                instanceSize: selectedInstanceSize?.code,
                volumeType: selectedVolumeType?.code,
                volumeSize: restoreDatastore.volumeSize,
                volumeIops: restoreDatastore.volumeIops,
            });
        }
    }, [isBackupRestore, restoreDatastore, deploymentOptions, cloudProvider]);

    const handleStorageTypeChange = (e: any) => {
        setStorageType(e);
    };

    const instanceSizeFieldAlert =
        !form.getFieldValue('instanceSize') && storageItems.length === 0;

    const instanceSizeAndStorageFieldAlert =
        storageItems.length !== 0 &&
        (!form.getFieldValue('storageType') ||
            !form.getFieldValue('instanceSize'));

    return (
        <>
            <p>
                <strong>Instance and Storage</strong>
                <InfoIcon
                    info={
                        <span>
                            Select the instance matching your needs. Instances
                            types can be changed later.
                        </span>
                    }
                />
            </p>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="instanceSize"
                        label="Instance"
                        rules={[
                            {
                                required: true,
                                message: 'Select your preferred instance',
                            },
                        ]}
                    >
                        <Select
                            optionFilterProp="children"
                            placeholder="Select instance size"
                            data-testid={`${testId}SizeSelect`}
                        >
                            {cloudProvider &&
                                deploymentOptions
                                    ?.getInstanceSizes(cloudProvider)
                                    .map((s: InstanceSize) => (
                                        <Select.Option
                                            key={s.code}
                                            value={s.code}
                                            onChange={handleInstanceSizeChange}
                                        >
                                            <Row>
                                                <Col
                                                    span={24}
                                                    data-testid={`${testId}SizeName`}
                                                >
                                                    {s.name}
                                                </Col>
                                                <Col
                                                    span={24}
                                                    className={
                                                        styles.InstanceSizeFormInstanceSize
                                                    }
                                                >
                                                    CPU: {s.cpu} | RAM: {s.ram}
                                                </Col>
                                            </Row>
                                        </Select.Option>
                                    ))}
                        </Select>
                    </Form.Item>
                </Col>
                {storageItems.length > 0 && (
                    <Col span={12}>
                        <Form.Item
                            name="storageType"
                            label="Storage"
                            rules={[
                                {
                                    required: true,
                                    message: 'Select a storage type',
                                },
                            ]}
                        >
                            <Select
                                value={storageType}
                                data-testid={`${testId}StorageTypeSelect`}
                                onChange={handleStorageTypeChange}
                                placeholder="Select storage type"
                            >
                                {storageItems.map((t: StorageItem) => (
                                    <Select.Option
                                        value={t.code}
                                        data-testid={`${testId}StorageTypeSelect${t.code}`}
                                    >
                                        <Row>
                                            <Col span={24}>
                                                {t.label}{' '}
                                                {t.info && (
                                                    <InfoIcon info={t.info} />
                                                )}
                                            </Col>
                                        </Row>
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                )}
            </Row>

            {(instanceSizeFieldAlert || instanceSizeAndStorageFieldAlert) && (
                <Alert
                    className={styles.InstanceSizeFormAlert}
                    message="To select volume type first you have to choose storage"
                    showIcon={true}
                    type="info"
                />
            )}

            {(!instanceSizeFieldAlert || !instanceSizeAndStorageFieldAlert) && (
                <InstanceVolumeTypeForm
                    form={form}
                    selectedTech={selectedTech}
                    deploymentOptions={deploymentOptions}
                    handleVolumeTypeChange={handleVolumeTypeChange}
                    volumeSize={volumeSize}
                    volumeType={volumeType}
                    volumeTypes={volumeTypes}
                    volumeIops={volumeIops}
                    storageItems={storageItems}
                    enteredVolumeSize={enteredVolumeSize}
                    testId={testId}
                    onVolumeSizeChange={onVolumeSizeChange}
                    onIOPSChange={onIOPSChange}
                />
            )}
        </>
    );
}
