import { DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import styles from './DataStoreViewLogs.module.less';
import moment from 'moment';
import { DbLogsEntries } from '../../types/DbLogsEnteries';
import { DbLogEntry } from '../../types/DbLogEntry';
import AppTable from '../AppTable';

interface DataStoreViewLogsProps {
    readonly testId?: string;
    readonly entries?: DbLogsEntries;
    readonly showModal: boolean;
    readonly fileName: string;
    readonly onCloseModal: () => void;
    readonly downloadLogs: () => Promise<void>;
}

interface HostnameOption {
    value: string;
    label: string;
}

function DataStoreViewLogs({
    testId = 'DataStoreViewLogs',
    entries,
    showModal,
    fileName,
    downloadLogs,
    onCloseModal,
}: DataStoreViewLogsProps) {
    const [latestEntry, setLatestEntry] = useState<DbLogEntry | undefined>();
    const [hostNamesOptions, setHostNamesOptions] = useState<HostnameOption[]>(
        []
    );
    const [filteredEntries, setFilteredEntries] = useState<DbLogEntry[]>([]);
    const [selectedHostname, setSelectedHostname] = useState<any>('all');
    const [showAllLogs, setShowAllLogs] = useState<boolean>(false);

    const findLatestEntry = (entries: DbLogsEntries | undefined) => {
        let latestEntry: DbLogEntry | undefined;
        entries?.entries.forEach((entry: DbLogEntry) => {
            if (!latestEntry) {
                latestEntry = entry;
            } else if (entry.timestamp > latestEntry.timestamp) {
                latestEntry = entry;
            }
        });
        return latestEntry;
    };

    const handleHostnameChange = (value: string) => {
        setSelectedHostname(value);
    };

    useEffect(() => {
        if (!entries?.entries?.length) {
            setFilteredEntries([]);
            return;
        }

        const filterEntries = (entriesToFilter: DbLogEntry[]) => {
            if (selectedHostname !== 'all') {
                return entriesToFilter.filter(
                    (entry) =>
                        entry.hostname.replace(/-int$/, '') === selectedHostname
                );
            }
            return entriesToFilter;
        };

        const sliceEntries = (entriesToSlice: DbLogEntry[]) =>
            showAllLogs ? entriesToSlice : entriesToSlice.slice(0, 10);

        const filteredAndSlicedEntries = sliceEntries(
            filterEntries(entries.entries)
        );
        setFilteredEntries(filteredAndSlicedEntries);
    }, [selectedHostname, entries, showAllLogs]);

    useEffect(() => {
        return () => {
            setLatestEntry(undefined);
        };
    }, []);

    useEffect(() => {
        if (entries) {
            const tempLatestEntry = findLatestEntry(entries);
            const tempHostNames = getUniqueHostnames(entries.entries);
            setHostNamesOptions(tempHostNames);
            setLatestEntry(tempLatestEntry);
        }
    }, [entries]);

    const getUniqueHostnames = (logEntries: DbLogEntry[]): HostnameOption[] => {
        const hostnameSet = new Set<string>();

        logEntries.forEach((entry) => {
            let hostname = entry.hostname.replace(/-int$/, '');
            hostnameSet.add(hostname);
        });

        const hostnameObjects = Array.from(hostnameSet).map((hostname) => ({
            value: hostname,
            label: hostname,
        }));

        return [{ value: 'all', label: 'All' }, ...hostnameObjects];
    };

    const logsTable = [
        {
            title: 'Hostname',
            key: 'hostname',
            dataIndex: 'hostname',
            render: (hostname: string) => hostname.replace('-int', ''),
            width: 100,
        },
        {
            title: 'Time',
            key: 'timestamp',
            dataIndex: 'timestamp',
            render: (timestamp: string) =>
                moment(timestamp).format('YYYY-MM-DD HH:mm'),
            width: 150,
        },
        {
            title: 'Message',
            key: 'message',
            dataIndex: 'message',
            render: (message: string) => formatLogMessage(message),
            width: 700,
        },
    ];

    return (
        <>
            <Modal
                title={`Log: ${fileName}`}
                open={showModal}
                data-testid={`${testId}Modal`}
                width={1100}
                onCancel={() => {
                    setShowAllLogs(false);
                    onCloseModal();
                }}
                footer={
                    <Row justify="end">
                        <Col>
                            <Button
                                type="primary"
                                data-testid={`${testId}CancelButton`}
                                onClick={() => {
                                    setShowAllLogs(false);
                                    onCloseModal();
                                }}
                            >
                                Close
                            </Button>
                        </Col>
                    </Row>
                }
            >
                <Row justify={'space-between'}>
                    <Col>
                        <h4 className={styles.LogInnerHeading}>Log Details</h4>
                    </Col>
                    <Col>
                        <Button
                            className={styles.DownloadLogsButton}
                            type="text"
                            icon={<DownloadOutlined />}
                            onClick={downloadLogs}
                        >
                            Download
                        </Button>
                    </Col>
                </Row>
                <Row
                    gutter={[16, 16]}
                    justify={'center'}
                    className={styles.DataStoreDetailsCard}
                >
                    <Col span={2}>
                        <div className={styles.DataStoreViewLogsIcon}>
                            <InfoCircleOutlined style={{ fontSize: '32px' }} />
                        </div>
                    </Col>
                    <Col span={22}>
                        <Row gutter={[16, 16]}>
                            <Col span={8}>
                                <p className={styles.LogDetailsLabel}>
                                    Last written date
                                </p>
                                <p className={styles.LogDetailsText}>
                                    {moment(latestEntry?.timestamp).format(
                                        'MMMM D, YYYY, HH:mm'
                                    )}
                                </p>
                            </Col>
                            <Col span={8}>
                                <p className={styles.LogDetailsLabel}>
                                    Last fetched date
                                </p>
                                <p className={styles.LogDetailsText}>
                                    {moment(latestEntry?.timestamp).format(
                                        'MMMM D, YYYY, HH:mm'
                                    )}
                                </p>
                            </Col>
                            <Col span={8}>
                                <p className={styles.LogDetailsLabel}>
                                    Log file name
                                </p>
                                <p className={styles.LogDetailsText}>
                                    {latestEntry?.filename
                                        ? latestEntry?.filename
                                        : fileName}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <hr className={styles.LogsDivider} />
                <Row
                    justify={'space-between'}
                    className={styles.LogsEntriesHeader}
                >
                    <Col>
                        <h4 className={styles.LogInnerHeading}>Log Messages</h4>
                    </Col>
                    <Col>
                        <strong>Host</strong>
                        <Select
                            value={selectedHostname}
                            data-testid={`${testId}Select`}
                            className={styles.HostsSelect}
                            onChange={(e) => handleHostnameChange(e)}
                        >
                            {hostNamesOptions.map((option) => (
                                <Select.Option
                                    data-testid={`${testId}SelectOption`}
                                    key={option.value}
                                >
                                    {option.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
                <Row className={styles.LogsEntriesTable} justify={'center'}>
                    <Col span={24}>
                        <AppTable
                            size="small"
                            expandable={false}
                            data={filteredEntries}
                            columns={logsTable}
                            rowKey={'localEntries'}
                            pagination={false}
                        />
                    </Col>
                    <Row justify={'center'}>
                        {filteredEntries.length >= 10 && (
                            <Col span={24}>
                                <Button
                                    type="link"
                                    onClick={() => setShowAllLogs(!showAllLogs)}
                                >
                                    {showAllLogs
                                        ? 'View less messages'
                                        : 'View more messages'}
                                </Button>
                            </Col>
                        )}
                    </Row>
                </Row>
            </Modal>
        </>
    );
}

export default DataStoreViewLogs;

const formatLogMessage = (message: string) => {
    const dateRegex =
        /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}Z)|(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}(?:\.\d{1,3})?(?:\s?[A-Z]{3,4})?)|(\d{1,2} \w{3} \d{4} \d{2}:\d{2}:\d{2}\.\d{3})/;
    const dateMatch = message.match(dateRegex);

    if (dateMatch) {
        const date = dateMatch[0];
        const restOfLog = message.replace(date, '').trim();
        return restOfLog;
    } else {
        return message.trim();
    }
};
