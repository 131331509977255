import styles from './DatastoreLogs.module.less';
import AppTable from '../AppTable';
import CcxComponentProps from '../../core/CcxComponent';
import DeploymentsItem from '../../types/DeploymentsItem';
import DataStoreLogsActions from './DataStoreLogsActions';
import AppEmpty from '../AppEmpty';
import useDataStoreEntries from '../../core/hooks/useDataStoreEntries';
import DbLogs from '../../types/DbLogs';
import { useEffect, useState } from 'react';
import { DbLogsEntries } from '../../types/DbLogsEnteries';
import { DbLogEntry } from '../../types/DbLogEntry';
import moment from 'moment';
import LazyLoader from '../LazyLoader';
import DataStoreViewLogs from './DataStoreViewLogs';
import DataStoreLogsService from '../../services/DataStoreLogsService';

interface Props extends CcxComponentProps {
    readonly currentDeployment?: DeploymentsItem | null | undefined;
    readonly dataStoreUuid: string;
    readonly logs: DbLogs;
    readonly testId?: string;
}

const DatastoreLogs = ({
    currentDeployment,
    dataStoreUuid,
    logs,
    testId = 'DatastoreLogs',
}: Props) => {
    const [logsTable, setLogsTable] = useState();
    const { dataStoreEntries, loading } = useDataStoreEntries(
        dataStoreUuid,
        logs
    );
    const [showModal, setShowModal] = useState(false);
    const [logsFileName, setLogsFilename] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [entries, setEntries] = useState<DbLogsEntries>();
    const [isClicked, setIsClicked] = useState<boolean>(false);

    useEffect(() => {
        if (dataStoreEntries) groupedLogs();
    }, [dataStoreEntries]);

    const onCloseModal = () => {
        setShowModal(false);
        setLogsFilename('');
        setEntries(undefined);
    };

    const groupedLogs = () => {
        let newData: any = [];
        const seenFiles = new Set();

        logs.files.forEach((log: any) => {
            dataStoreEntries?.forEach((entries: any) => {
                entries?.entries?.forEach((entry: any) => {
                    if (entry.filename === log.name) {
                        if (!seenFiles.has(log.name)) {
                            newData.push({
                                name: log.name,
                                when: findLatestEntry(entries),
                            });
                            seenFiles.add(log.name);
                        }
                    }
                });
            });
        });

        logs.files.forEach((log: any) => {
            if (!seenFiles.has(log.name)) {
                newData.push({
                    name: log.name,
                });
            }
        });

        setLogsTable(newData);
    };

    const findLatestEntry = (entries: DbLogsEntries | undefined) => {
        let latestEntry: DbLogEntry | undefined;

        entries?.entries.forEach((entry: DbLogEntry) => {
            if (!latestEntry) {
                latestEntry = entry;
            } else if (entry.timestamp > latestEntry.timestamp) {
                latestEntry = entry;
            }
        });

        return latestEntry?.timestamp;
    };

    const handleViewLogs = async (fileName: string) => {
        if (isClicked) return;
        setIsLoading(true);
        setLogsFilename(fileName);
        try {
            const response = await DataStoreLogsService.getDataStoreLogsEntries(
                dataStoreUuid,
                fileName
            );
            setEntries(response);
            setShowModal(true);
        } catch (e) {
            console.log('error', e);
        }
        setIsLoading(false);
        setIsClicked(false);
    };

    const downloadDataStoreLogs = async (fileName: string) => {
        setIsLoading(true);
        try {
            const response = await DataStoreLogsService.downloadDataStoreLogs(
                dataStoreUuid,
                fileName
            );

            const report = await response.blob();
            let filename = `${fileName}-DatastoreLogs`;
            const contentDisposition = response.headers.get(
                'Content-Disposition'
            );

            if (contentDisposition) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(contentDisposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
            }
            await downloadLogs(report, filename);
        } catch (error) {
            console.error('Error downloading logs:', error);
        }
        setIsLoading(false);
    };

    const downloadLogs = async (reportFile: any, fileName: string) => {
        const url = window.URL.createObjectURL(reportFile);
        const reportFileLink = document.createElement('a');
        reportFileLink.href = url;
        document.body.appendChild(reportFileLink);
        reportFileLink.download = fileName;
        reportFileLink.click();
        window.URL.revokeObjectURL(url);
    };

    const logsColumns = [
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            onCell: (record: any) => {
                return {
                    onClick: () => {
                        if (record?.when) {
                            setIsClicked(true);
                            handleViewLogs(record?.name);
                        }
                    },
                };
            },
        },
        {
            title: 'When',
            key: 'when',
            dataIndex: 'when',
            onCell: (record: any) => {
                return {
                    onClick: (e: any) => {
                        if (record?.when) {
                            setIsClicked(true);
                            handleViewLogs(record?.name);
                        }
                    },
                };
            },
            render: (timeStamp: string, record: any) => {
                return record?.when ? (
                    <div>{moment(record?.when).format('YYYY-MM-DD HH:mm')}</div>
                ) : (
                    <></>
                );
            },
        },
        {
            title: 'Actions',
            key: 'action',
            dataIndex: 'actions',
            align: 'right',
            width: 100,
            render: (_: any, record: any) => {
                return (
                    <DataStoreLogsActions
                        disabled={!record?.when}
                        isLoading={isLoading}
                        handleViewLogs={() => handleViewLogs(record?.name)}
                        downloadDataStoreLogs={() =>
                            downloadDataStoreLogs(record?.name)
                        }
                    />
                );
            },
        },
    ];

    return currentDeployment && logs ? (
        <section data-testid={testId} className={styles.DatastoreLogs}>
            <h4 className={styles.DbLogsLabel}>Logs</h4>
            {loading ? (
                <LazyLoader type="row" />
            ) : (
                <>
                    <AppTable
                        columns={logsColumns}
                        data={logsTable}
                        rowKey="logs"
                        expandable={false}
                        pagination={{
                            hideOnSinglePage: true,
                            pageSize: 5,
                            position: ['bottomCenter'],
                        }}
                    />
                    <DataStoreViewLogs
                        showModal={showModal}
                        fileName={logsFileName}
                        onCloseModal={onCloseModal}
                        downloadLogs={() => downloadDataStoreLogs(logsFileName)}
                        entries={entries}
                    />
                </>
            )}
        </section>
    ) : (
        <AppEmpty message="There are no logs available yet" />
    );
};

export default DatastoreLogs;
