import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import styles from './QueryStats.module.less';
import AppEmpty from '../AppEmpty';
import { useHistory, useParams } from 'react-router-dom';
import useTopQueries from '../../core/hooks/useTopQueries';
import useQueryProcesses from '../../core/hooks/useQueryProcesses';
import AppTable from '../AppTable';
import AppLoading from '../AppLoading';
import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import CopyToClipboardText from '../CopyToClipboardText';
import TopQuery from '../../types/TopQuery';
import TopQueriesTable from './topQueries/TopQueriesTable';
import useDataStoreServices from '../../core/hooks/useDataStoreServices';
import Service from '../../types/Service';
import ServiceInstanceFormat from '../projects/dataStores/services/ServiceInstanceFormat';
import { useAppSelector } from '../../redux/hooks';
import DeploymentsItem from '../../types/DeploymentsItem';

interface UrlProps {
    dataStoreUuid: string;
    projectUuid: string;
}

type QueryStatsProps = {
    testId?: string;
    currentDeployment: DeploymentsItem;
};

function QueryStats({
    testId = 'QueryStats',
    currentDeployment,
}: QueryStatsProps): ReactElement {
    const [showConnections, setShowConnections] =
        useState<string>('dbConnections');
    const { dataStoreUuid } = useParams<UrlProps>();
    const { topQueries, loading: topQueryLoading } =
        useTopQueries(dataStoreUuid);
    const {
        queryProcesses,
        refresh,
        loading: processLoading,
    } = useQueryProcesses(dataStoreUuid);

    const {
        services,
        refresh: servicesRefresh,
        loading: servicesLoading,
        updating,
    } = useDataStoreServices({
        dataStoreUuid,
    });

    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

    const history = useHistory();

    useEffect(() => {
        if (currentDeployment?.isRedis()) {
            history.goBack();
        }
    }, [currentDeployment]);

    const findInstanceService = (instance?: string) => {
        return (
            instance &&
            services?.dbServices?.find(
                (service: Service) =>
                    service.getFQDN() === instance ||
                    service.getFQDN() === instance?.split(':')[0]
            )
        );
    };

    const topQueriesColumns = [
        {
            title: 'Node',
            dataIndex: 'instance',
            key: 'instance',
            render: (text: string, record: any) => {
                const service = findInstanceService(record.instance);
                return (
                    <ServiceInstanceFormat
                        instance={record.instance}
                        dataStore={currentDeployment}
                        service={service}
                    />
                );
            },
        },
        {
            title: 'Query digest',
            dataIndex: 'digestText',
            key: 'digestText',
            width: '200px',
            render: (text: string, record: any) => (
                <CopyToClipboardText
                    text={record.digestText}
                    tooltip={record.digestText}
                    preview={
                        text.length > 14 ? `${text.substring(0, 14)}...` : text
                    }
                    hoverable={false}
                    buttonProps={{
                        textSize: 'small',
                    }}
                />
            ),
        },
        {
            title: 'Database',
            dataIndex: 'database',
            key: 'database',
        },

        {
            title: 'Count',
            dataIndex: 'countStar',
            key: 'countStar',
            sorter: (a: any, b: any) => a.countStar - b.countStar,
            width: '100px',
        },

        {
            title: 'Execution time',
            children: [
                {
                    title: 'Absolute',
                    key: 'sumTime',
                    width: '100px',
                    render: (text: string, record: any) =>
                        record.sumTimeFormatted,
                    sorter: (a: any, b: any) => a.sumTime - b.sumTime,
                },
                {
                    title: 'Relative %',
                    key: 'sumTime',
                    render: (text: string, record: any) => {
                        if (topQueries?.totalExecutionTime) {
                            const percent =
                                Math.round(
                                    (record.sumTime /
                                        topQueries?.totalExecutionTime) *
                                        100 *
                                        100
                                ) / 100;
                            return percent;
                        }
                        return 0;
                    },
                },
            ],
        },
        {
            title: 'Avg',
            dataIndex: 'avgTime',
            key: 'avgTime',
            render: (text: string) => <div>{parseFloat(text).toFixed(1)}s</div>,
            sorter: (a: any, b: any) => a.avgTime - b.avgTime,
            width: '100px',
        },
    ];

    if (!currentDeployment?.isPostgreSql()) {
        topQueriesColumns.push({
            title: 'Last seen',
            dataIndex: 'lastSeen',
            key: 'lastSeen',
        });
    }

    const queryProcessesColumns = [
        {
            title: 'Node',
            dataIndex: 'instance',
            key: 'instance',
            render: (text: string, record: any) => {
                const service = findInstanceService(record.instance);
                return (
                    <ServiceInstanceFormat
                        instance={record.instance}
                        dataStore={currentDeployment}
                        service={service}
                    />
                );
            },
        },
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
        },
        {
            title: 'Client',
            dataIndex: 'client',
            key: 'client',
        },
        {
            title: 'Query ',
            dataIndex: 'query',
            key: 'query',
            width: '350px',
            render: (text: string, record: any) =>
                record.query && (
                    <CopyToClipboardText
                        text={record.query}
                        tooltip={record.query}
                        preview={
                            text.length > 35
                                ? `${text.substring(0, 35)}...`
                                : text
                        }
                        hoverable={false}
                    />
                ),
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
        },
        {
            title: 'Query time',
            key: 'queryTime',
            render: (text: any, record: any) => (
                <span>{record.queryTime} sec</span>
            ),
        },
    ];

    const onChange = (e: RadioChangeEvent) => {
        setShowConnections(e.target.value);
    };

    const rowExpandableTopQuery = (record: TopQuery) => {
        return record.countStar;
    };

    const expandedRowRenderTopQuery = (record: TopQuery) => {
        return <TopQueriesTable query={record} />;
    };

    const onRow = (record: TopQuery) => {
        return (
            expandedKeys.includes(record.uniqueKey) && {
                className: styles.QueryStatsExpanded,
            }
        );
    };

    const onExpand = (expanded: any, { uniqueKey }: TopQuery) => {
        const keys = expandedKeys;

        const tempExpandedKeys = expanded
            ? keys.concat(uniqueKey)
            : keys.filter((k) => k !== uniqueKey);
        setExpandedKeys(tempExpandedKeys);
    };

    return (
        <section data-testid={testId} className={styles.QueryStats}>
            <Radio.Group
                value={showConnections}
                onChange={onChange}
                data-testid={`${testId}RadioGroup`}
                className={styles.QueryStatsRadio}
            >
                <Radio.Button value="dbConnections">
                    Databases connections
                </Radio.Button>
                <Radio.Button value="topQueries">Top queries</Radio.Button>
            </Radio.Group>

            {showConnections === 'dbConnections' && (
                <>
                    {processLoading ? (
                        <AppLoading />
                    ) : queryProcesses?.queryProcesses ? (
                        <AppTable
                            columns={queryProcessesColumns}
                            data={queryProcesses?.queryProcesses}
                            rowKey="uniqueKey"
                            pagination={{
                                hideOnSinglePage: true,
                                position: [`bottomCenter`],
                            }}
                        />
                    ) : (
                        <AppEmpty message="There are no active database connections at the moment" />
                    )}
                </>
            )}

            {showConnections === 'topQueries' && (
                <div data-testid={`${testId}topQueries`}>
                    {topQueryLoading ? (
                        <AppLoading />
                    ) : topQueries ? (
                        <AppTable
                            columns={topQueriesColumns}
                            data={topQueries?.topQueries.slice(0, 10)}
                            rowKey="uniqueKey"
                            pagination={{
                                hideOnSinglePage: true,
                                position: [`none`],
                                defaultPageSize: 10,
                            }}
                            expandedRowRender={expandedRowRenderTopQuery}
                            rowExpandable={rowExpandableTopQuery}
                            onRow={onRow}
                            onExpand={onExpand}
                            customIcon={true}
                            expandableIconVariant="topQueries"
                            className={styles.QueryStatsHeader}
                        />
                    ) : (
                        <AppEmpty message="There are no top queries yet" />
                    )}
                </div>
            )}
        </section>
    );
}

export default QueryStats;
