import DatastoreScalingStepHeader from '../DatastoreScalingStepHeader';
import { Col, Form, InputNumber, Row, Select } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import styles from './DatastoreVolumeCard.module.less';
import {
    DatastoreConfigurations,
    SelectOptions,
} from './DataStoreNodeConfiguration';
import InstanceVolumeType from '../../../../types/InstanceVolumeType';

interface DatastoreVolumeCardProps {
    readonly testId?: string;
    readonly selectOptions: SelectOptions;
    readonly configuration: DatastoreConfigurations | undefined;
    readonly onChangeConfigurations: Function;
}

const IO1: string = 'io1';

function DatastoreVolumeCard({
    selectOptions,
    configuration,
    onChangeConfigurations,
    testId = 'DatastoreVolumeCard',
}: DatastoreVolumeCardProps) {
    return (
        <DatastoreScalingStepHeader title="Volume type" subtitle="">
            <Row gutter={[16, 16]} data-testid={testId}>
                <Col span={24} className={styles.InstanceVolumeTypeCol}>
                    <Form.Item
                        name="volumeType"
                        className={styles.InstanceVolumeTypeForm}
                        label=""
                        rules={[
                            {
                                required: true,
                                message: 'Select a volume type',
                            },
                        ]}
                    >
                        <Select
                            optionFilterProp="children"
                            value={configuration?.storageType}
                            defaultValue={configuration?.storageType}
                            onChange={(value) => {
                                onChangeConfigurations({
                                    ...configuration,
                                    storageType: value,
                                });
                            }}
                            size="large"
                            data-testid={`${testId}VolumeTypeSelect`}
                        >
                            {selectOptions?.volumeTypes.map(
                                (t: InstanceVolumeType) => (
                                    <Select.Option key={t.code} value={t.code}>
                                        <Row>
                                            <Col>
                                                {t.name}{' '}
                                                {t.info && (
                                                    <InfoIcon info={t.info} />
                                                )}
                                            </Col>
                                        </Row>
                                    </Select.Option>
                                )
                            )}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    {configuration?.storageType === IO1 && (
                        <div>
                            <p>
                                <strong>IOPS</strong>
                            </p>
                            <Form.Item
                                name="volumeIops"
                                label=""
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select IOPS',
                                    },
                                ]}
                            >
                                <InputNumber
                                    className={
                                        styles.InstanceVolumeTypeFormInputNumber
                                    }
                                    defaultValue={configuration.iops}
                                    min={1000}
                                    onChange={(value) => {
                                        onChangeConfigurations({
                                            ...configuration,
                                            iops: value,
                                        });
                                    }}
                                    size="large"
                                    data-testid={`${testId}VolumeIopsInput`}
                                />
                            </Form.Item>
                        </div>
                    )}
                </Col>
            </Row>
        </DatastoreScalingStepHeader>
    );
}

export default DatastoreVolumeCard;
