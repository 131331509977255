import { ReactElement, useEffect, useState } from 'react';
import ButtonModalForm from '../ButtonModalForm';
import DbUserService, { AddUserForm } from '../../services/DbUserService';
import DbDatabase from '../../types/DbDatabase';
import { Modal, notification } from 'antd';
import CcxIconUserAddOutlined from '../ccx/icons/CcxIconUserAddOutlined';
import CcxIconCheckCircle from '../ccx/icons/CcxIconCheckCircle';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';
import DeploymentsItem from '../../types/DeploymentsItem';
import { getDatabaseUsernameValidators } from '../../core/validation/databaseValidation';
import { DatabaseType } from '../../types/Db';

type AddDbUserProps = {
    uuid: string;
    onSuccess: Function;
    databases?: DbDatabase[] | undefined;
    currentDeployment?: DeploymentsItem | null | undefined;
    disabled?: boolean;
    defaultAdmin?: string;
};

const isDefaultAdminMessage =
    'You are trying to update the default admin, but the default admin user cannot be updated.';
const isDefaultAdminTitle = 'Default Admin cannot be overwritten';
const isAdminExistsTitle = 'User Will Be Overwritten';
const isAdminExistsMessage =
    "Please note that updating this database user will replace the existing user's permissions and password. Ensure that this action is intentional, as it will affect their access.";
const isDefaultAdmin = 'defaultAdmin';

function AddDbUser({
    onSuccess,
    uuid,
    databases,
    currentDeployment,
    disabled = false,
    defaultAdmin,
}: AddDbUserProps): ReactElement {
    const fieldsSetup = [
        {
            name: ['dbUsername'],
            testId: 'AddDbUserDbUsername',
            required: true,
            label: 'Username',
            groupBy: 'UserCredentials',
            validators: getDatabaseUsernameValidators(
                (currentDeployment?.getServiceName() as DatabaseType) ||
                    DatabaseType.UNKNOWN
            ),
        },
        {
            name: ['dbPassword'],
            testId: 'AddDbUserDbPassword',
            required: true,
            label: 'Password',
            groupBy: 'UserCredentials',
            type: 'password',
        },
        {
            name: ['dbName'],
            testId: 'AddDbUserDbName',
            required: true,
            label: 'Database name',
            placeholder: 'Select a database',
            type: 'select',
            options: [],
        },
        {
            name: ['dbAuthPlugin'],
            testId: 'AddDbAuthPlugin',
            required: true,
            label: 'Authentication plugin',
            placeholder: 'Select authentication plugin',
            type: 'select',
            options: [
                {
                    label: 'caching_sha2_password',
                    value: 'caching_sha2_password',
                },
                {
                    label: 'mysql_native_password',
                    value: 'mysql_native_password',
                },
            ],
            defaultValue: 'caching_sha2_password',
        },
        {
            name: ['allowedHost'],
            testId: 'AddDbUserAllowedHost',
            required: false,
            label: 'Allowed host',
            placeholder: 'Enter the host that is allowed to connect from.',
        },
        {
            name: ['dbUserDbCommandCategories'],
            testId: 'AddDbUserDbCommandCategories',
            required: true,
            label: 'Categories',
            placeholder: '-@dangerous and -@admin always included',
            defaultValue: '+@all',
            validators: [
                {
                    message:
                        'ensure the category  name as a @ sign and starts with a + or -, e.g +@all',
                    pattern:
                        /^\s*(\+@|-@)([^\s^,]+)(?:\s*(?:\+@|-@)([^\s^,]+)\s*)*\s*$/,
                },
            ],
        },
        {
            name: ['dbUserDbCommands'],
            testId: 'AddDbUserDbCommands',
            required: false,
            label: 'Commands',
            placeholder: 'starts with a "+" or "-" (+get or -get)',
            validators: [
                {
                    pattern: /^\s*[+-]([^\s^,]+)(?:\s*[+-]([^\s^,]+)\s*)*\s*$/,
                    message:
                        'ensure commands starts with a + or - (e.g +get -set)',
                },
            ],
        },
        {
            name: ['dbUserDbChannels'],
            testId: 'AddDbUserDbChannels',
            required: false,
            label: 'Channels',
            placeholder: 'starts with "&" (&channel, or &*)',
            validators: [
                {
                    pattern: /^\s*&([^\s^,]+)(?:\s*&([^\s^,]+)\s*)*\s*$/,
                    message:
                        'ensure the channel name starts with a & (e.g &channel,  or &*)',
                },
            ],
        },
        {
            name: ['dbUserDbKeys'],
            testId: 'AddDbUserDbKeys',
            required: false,
            label: 'Keys',
            placeholder: 'starts with a "~" (~key, or ~*)',
            validators: [
                {
                    pattern: /^\s*~([^\s^,]+)(?:\s*~([^\s^,]+)\s*)*\s*$/,
                    message:
                        'ensure the key names starts with a ~ (~key,  or  ~*)',
                },
            ],
        },
    ];
    const [fields, setFields] = useState<any>(fieldsSetup);
    const [title, setTitle] = useState<any>('Create new user');
    const [isSuperUser, setIsSuperUser] = useState<boolean>(false);
    const [ownDatabase, setOwnDatabase] = useState<boolean>(false);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const { confirm } = Modal;

    const toggleSuperUser = (value: any) => {
        setIsSuperUser(value);
    };

    const toggleOwnDatabase = (value: any) => {
        setOwnDatabase(value);
    };

    const superUserField = {
        name: ['SuperUser'],
        type: 'switch',
        label: 'Grant SUPERUSER privilege',
        onChange: (value: any) => toggleSuperUser(value),
        defaultValue: isSuperUser,
        testId: 'SuperDbUser',
        groupBy: 'SuperUser',
        className: 'SuperUserSwitch',
    };
    const ownDatabaseField = {
        name: ['ownDatabase'],
        type: 'switch',
        label: 'Create own database',
        onChange: (value: any) => toggleOwnDatabase(value),
        defaultValue: isSuperUser,
        testId: 'CreateOwnDatabase',
        groupBy: 'SuperUser',
        className: 'SuperUserSwitch',
    };

    const postgresFields = () => {
        return [superUserField, ownDatabaseField];
    };

    useEffect(() => {
        if (databases !== undefined && databases?.length > 0) {
            const newFields = fields.map((f: any) => {
                if (f.name && f.name[0] === 'dbName') {
                    f.options = databases
                        ? databases.map((d) => {
                              return {
                                  label: d.databaseName,
                                  value: d.databaseName,
                              };
                          })
                        : [];
                }
                return f;
            });
            setFields(newFields);
        }
    }, [databases]);

    useEffect(() => {
        setTitle('Create Admin user');
        if (currentDeployment?.isPostgreSql() && fields) {
            setFields([...fields.slice(0, 2), ...postgresFields()]);
        } else if (currentDeployment?.isMSSQL() && fields) {
            setFields(fields.slice(0, 2));
        } else if (currentDeployment?.isMariaDb() && fields) {
            setFields(fields.slice(0, 3));
        } else if (currentDeployment?.isPercona() && fields) {
            setFields(fields.slice(0, 4));
        } else if (currentDeployment?.isRedis() && fields) {
            setFields([
                fieldsSetup[0],
                fieldsSetup[1],
                fieldsSetup[5],
                fieldsSetup[6],
                fieldsSetup[7],
                fieldsSetup[8],
            ]);
        }
    }, [currentDeployment, isSuperUser]);

    const onCancel = () => {
        // do something here
    };

    const checkUsernameAvailability = async (
        username: string
    ): Promise<string | boolean> => {
        try {
            if (defaultAdmin === username) {
                return isDefaultAdmin;
            }
            const allUsers = await DbUserService.getAllUsers(uuid);
            const user = allUsers.accounts.find((u) => u.username === username);
            if (user) {
                return 'admin';
            }
            return false;
        } catch (e) {
            return false;
        }
    };

    const addDbUserRequest = async ({
        dbUsername,
        dbPassword,
        allowedHost,
        dbName,
        dbUserDbCommandCategories,
        dbUserDbCommands,
        dbUserDbKeys,
        dbUserDbChannels,
        isAdmin = true,
        dbAuthPlugin,
    }: AddUserForm) => {
        try {
            await DbUserService.addUser({
                uuid,
                dbUsername,
                dbPassword,
                allowedHost,
                dbName,
                dbPrivileges: [
                    dbUserDbCommandCategories,
                    dbUserDbCommands,
                    dbUserDbKeys,
                    dbUserDbChannels,
                ].join(' '),
                isAdmin,
                dbAuthPlugin,
                isSuperUser,
                ownDatabase,
            });

            notification.open({
                message: 'Add user',
                description: `New user successfully added!`,
                icon: <CcxIconCheckCircle />,
            });
            setIsSuperUser(false);
            setOwnDatabase(false);
            setIsModalVisible(false);
            return true;
        } catch (e) {
            notification.open({
                message: 'Add user',
                description: `There was an error adding your user. ${e}`,
                icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
            });

            console.error(e);
            setIsSuperUser(false);
            setOwnDatabase(false);
            throw e;
        }
    };

    const doApiRequest = async (dbUser: AddUserForm) => {
        const { dbUsername } = dbUser;
        const isUserExists: string | boolean = await checkUsernameAvailability(
            dbUsername
        );
        if (isUserExists) {
            setIsModalVisible(true);
            confirm({
                title:
                    isUserExists === isDefaultAdmin
                        ? isDefaultAdminTitle
                        : isAdminExistsTitle,
                content:
                    isUserExists === isDefaultAdmin
                        ? isDefaultAdminMessage
                        : isAdminExistsMessage,
                okText: 'Confirm',
                okButtonProps: {
                    disabled: isUserExists === isDefaultAdmin,
                },

                cancelText: 'Cancel',
                onOk: async () => {
                    return await addDbUserRequest(dbUser).then(() => {
                        onSuccess();
                    });
                },
            });
            throw new Error('User already exists');
        } else {
            return await addDbUserRequest(dbUser);
        }
    };

    return (
        <ButtonModalForm
            disabled={!currentDeployment?.isOperable() || disabled}
            title={title}
            buttonText={title}
            buttonIcon={<CcxIconUserAddOutlined />}
            onSubmit={doApiRequest}
            onSuccess={onSuccess}
            onCancel={onCancel}
            fields={fields}
            testId={'AddDbUser'}
            submitText="Create"
            formLayout="vertical"
            useGrid={false}
            handleModalForcely={isModalVisible}
            warningMessage={
                isSuperUser
                    ? 'A database admin user with SUPERUSER privileges can execute commands that may leave the datastore in an unexpected, non-functional state, potentially requiring a backup restoration to resolve. With great power comes great responsibility'
                    : ''
            }
        >
            <span></span>
        </ButtonModalForm>
    );
}

export default AddDbUser;
