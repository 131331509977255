import React, { useState } from 'react';
import { DownloadOutlined } from '@severalnines/frontend_hub/libs/icons';
import { Button, Col, message, Row, Table, Typography } from 'antd';
import DownloadAuthCertificatesService from '../../../../services/DownloadingAuthService';

interface DatastoreSettingsAuthenticationTableProps {}

function DatastoreSettingsAuthenticationTable({}: DatastoreSettingsAuthenticationTableProps) {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const authColumns = [
        {
            title: <strong>Credentials</strong>,
            dataIndex: 'credentials',
            key: 'credentials',
            width: 500,
        },
        {
            title: <strong>Download</strong>,
            dataIndex: 'download',
            key: 'download',
            width: 100,
        },
    ];

    const downloadCertificate = async (reportFile: any, fileName: string) => {
        const url = window.URL.createObjectURL(reportFile);
        const reportFileLink = document.createElement('a');
        reportFileLink.href = url;
        document.body.appendChild(reportFileLink);
        reportFileLink.download = fileName;
        reportFileLink.click();
        window.URL.revokeObjectURL(url);
        setIsLoading(false);
    };

    const onClickDownloadCertificate = async (certificateType: string) => {
        setIsLoading(true);
        try {
            const response =
                await DownloadAuthCertificatesService.getDatastoreCertificate(
                    certificateType
                );
            const report = await response.blob();
            let filename = certificateType;
            const contentDisposition = response.headers.get(
                'Content-Disposition'
            );

            if (contentDisposition) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(contentDisposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
            }
            await downloadCertificate(report, filename);
        } catch (e) {
            let errorMessage = e instanceof Error ? e.toString() : String(e);
            message.error(
                `Error: Downloading datastore access key failed.  ${errorMessage}`
            );
            setIsLoading(false);
        }
    };

    const authDownloads = [
        {
            key: '1',
            credentials: 'CA Certificate',
            download: (
                <Button
                    loading={isLoading}
                    data-testid={'certificate-download'}
                    onClick={() => onClickDownloadCertificate('ca-certificate')}
                    icon={<DownloadOutlined />}
                >
                    Download
                </Button>
            ),
        },
    ];
    return (
        <>
            {' '}
            <Row>
                <Col span={24}>
                    <Typography.Title level={5}>
                        Authentication
                    </Typography.Title>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table
                        columns={authColumns}
                        dataSource={authDownloads}
                        pagination={false}
                    />
                </Col>
            </Row>
        </>
    );
}

export default DatastoreSettingsAuthenticationTable;
