import { useEffect, useState } from 'react';
import OrganizationsService from '../../services/OrganizationsService';

export default function useOrganization() {
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [organization, setOrganization] = useState<any>(undefined);

    const load = async () => {
        try {
            const response = await OrganizationsService.getAllOrganizations();
            setOrganization(response);
            setError(null);
        } catch (e: any) {
            setError(e);
        }
    };

    const refresh = () => {
        setUpdating(true);
        load();
        setUpdating(false);
    };

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            await load();
            setLoading(false);
        };

        init();
    }, []);

    return { organization, refresh, loading, updating, error };
}
