import { Select } from 'antd';
import { useHistory } from 'react-router-dom';
import { getLocalStorage, setLocalStorage } from '../../core/helpers';
import styles from './Organizations.module.less';
import { useState } from 'react';

interface OrganizationsProps {
    options: { label: string; value: string; type: string }[] | undefined;
}

const Organizations = ({ options }: OrganizationsProps) => {
    const [org, setOrg] = useState(getLocalStorage('org'));
    const history = useHistory();
    const onChangeOrganization = (value: string) => {
        const selectedOption = options?.find((o) => o.value === value);
        if (selectedOption) {
            setLocalStorage('org', {
                id: selectedOption.value,
                type: selectedOption.type,
            });
            setOrg({
                id: selectedOption.value,
                type: selectedOption.type,
            });
        }
        history.push(`/projects/${selectedOption?.value}/data-stores`);
        window.location.reload();
    };

    return options && options?.length > 1 ? (
        <Select
            className={styles.OrganizationSelect}
            placeholder="Select organization"
            style={{ width: 150 }}
            options={options}
            value={org?.id}
            onChange={onChangeOrganization}
        />
    ) : null;
};

export default Organizations;
