import { Divider } from 'antd';
import { Col } from 'antd';
import { Checkbox } from 'antd';
import { Tooltip } from 'antd';
import { Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { ReactElement, useState } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import { eulaEnabled, EULALink } from '../../../core/CcxEnv';
import CloudProvider from '../../../types/CloudProvider';
import DatabaseVendor from '../../../types/DatabaseVendor';
import Vpc from '../../../types/Vpc';
import AppFlagIcon from '../../ccx/common/AppFlagIcon';
import AppFormTag from '../../ccx/common/AppFormTag';
import styles from './WizardFormConfigurationStep5.module.less';
import DeploymentOptions from '../../../types/DeploymentOptions';
import { isOnlyPublicNetworkEnabled } from './WizardFormConfigurationStep4';
import Space from '../../common/antd/Space';

interface Props extends CcxComponentProps {
    logo: any;
    providerLogo: any;
    form: FormInstance;
    selectedTech?: DatabaseVendor;
    vpc?: Vpc;
    deploymentOptions?: DeploymentOptions;
    cloudProvider?: CloudProvider;
    version?: string;
    setEULA: Function;
    eula: boolean;
    StepsExtra: Function;
    hideCloudProviderStep: boolean;
}

export function WizardFormConfigurationStep5({
    testId = 'WizardFormConfigurationStep5',
    logo,
    providerLogo,
    selectedTech,
    form,
    vpc,
    version,
    cloudProvider,
    deploymentOptions,
    setEULA,
    eula,
    StepsExtra,
    hideCloudProviderStep,
}: Props): ReactElement {
    const [instanceDetails, setInstanceDetails] = useState();

    const handleEulaChange = () => {
        setEULA && setEULA(!eula);
    };
    const showNetwork = deploymentOptions && cloudProvider;

    return (
        <div data-testid={testId}>
            <p>
                <strong>Database</strong>
            </p>

            <Row className={styles.WizardFormConfigurationStep5Row}>
                <Col
                    span={24}
                    className={styles.WizardFormConfigurationStep5Col}
                >
                    {logo}
                    {`${selectedTech?.name} ${
                        version ? version : selectedTech?.version
                    }`}{' '}
                    {selectedTech?.beta && ` (Beta)`}
                </Col>
            </Row>

            <Divider />

            <p>
                <strong>Configuration</strong>
            </p>

            <Row className={styles.WizardFormConfigurationStep5Row}>
                <Col
                    span={24}
                    className={styles.WizardFormConfigurationStep5Col}
                >
                    <strong>Name: </strong>{' '}
                    <Tooltip
                        placement="bottom"
                        title={form.getFieldValue('deploymentName')}
                    >
                        {form.getFieldValue('deploymentName')?.length > 38
                            ? ` ${form
                                  .getFieldValue('deploymentName')
                                  .substring(0, 39)}...`
                            : ` ${form.getFieldValue('deploymentName')}`}
                    </Tooltip>
                </Col>

                <Col span={24}>
                    <Space
                        wrap={true}
                        wide={true}
                        align="end"
                        justify="end"
                        size={0}
                    >
                        {form.getFieldValue('tags')
                            ? form
                                  .getFieldValue('tags')
                                  ?.map((t: string, i: number) => (
                                      <AppFormTag
                                          key={`tag${i}`}
                                          label={t}
                                          closable={false}
                                      />
                                  ))
                            : null}
                    </Space>
                </Col>

                {form.getFieldValue('databaseVendorType') && (
                    <Col
                        span={24}
                        className={styles.WizardFormConfigurationStep5Col}
                    >
                        <strong>Configuration:</strong>{' '}
                        {form
                            .getFieldValue('databaseVendor')
                            ?.getVendorTypeNameByCode(
                                form.getFieldValue('databaseVendorType')
                            )}
                    </Col>
                )}

                <Col
                    span={24}
                    className={styles.WizardFormConfigurationStep5Col}
                >
                    <strong>Number of nodes:</strong>{' '}
                    {form.getFieldValue('databaseVendorType')
                        ? selectedTech
                              ?.getVendorTypeByCode(
                                  form.getFieldValue('databaseVendorType')
                              )
                              ?.getSizeHintsName(
                                  form.getFieldValue('numberOfNodes')
                              )
                        : form.getFieldValue('numberOfNodes')}
                </Col>
                <Col
                    span={24}
                    className={styles.WizardFormConfigurationStep5Col}
                >
                    <strong>Email Notifications:</strong>{' '}
                    {form.getFieldValue('notificationRecipients')?.join(', ') ||
                        'Disabled'}
                </Col>
            </Row>

            <Divider />

            {hideCloudProviderStep && (
                <>
                    <p>
                        <strong>Cloud service</strong>
                    </p>

                    <Row className={styles.WizardFormConfigurationStep5Row}>
                        <Col
                            span={24}
                            className={styles.WizardFormConfigurationStep5Col}
                        >
                            <strong>Cloud service provider:</strong>
                            {providerLogo}
                            {deploymentOptions?.getFullName(
                                form?.getFieldValue('cloudProvider')?.code
                            )}
                        </Col>

                        <Col
                            span={24}
                            className={styles.WizardFormConfigurationStep5Col}
                        >
                            <strong>Region and zone:</strong>
                            {
                                <AppFlagIcon
                                    code={
                                        form?.getFieldValue('region')
                                            ?.countryCode
                                    }
                                />
                            }{' '}
                            {form.getFieldValue('region')?.displayCode} (
                            {form.getFieldValue('region')?.city})
                        </Col>
                    </Row>

                    <Divider />
                </>
            )}

            <p>
                <strong>Instance</strong>
            </p>

            <Row className={styles.WizardFormConfigurationStep5Row}>
                {form.getFieldValue('instanceSize') && (
                    <Col
                        span={24}
                        className={styles.WizardFormConfigurationStep5Col}
                    >
                        <strong>Instance:</strong>{' '}
                        {`${
                            deploymentOptions?.getInstanceSizeInfo(
                                form.getFieldValue('cloudProvider'),
                                form.getFieldValue('instanceSize')
                            ).cpu
                        }vCPU, ${
                            deploymentOptions?.getInstanceSizeInfo(
                                form.getFieldValue('cloudProvider'),
                                form.getFieldValue('instanceSize')
                            ).ram
                        } GB RAM`}
                    </Col>
                )}

                {form.getFieldValue('volumeType') === undefined &&
                    form.getFieldValue('storageType') && (
                        <Col
                            span={24}
                            className={styles.WizardFormConfigurationStep5Col}
                        >
                            <strong>Storage type:</strong>{' '}
                            {
                                deploymentOptions?.getInstanceStorageType(
                                    form.getFieldValue('cloudProvider'),
                                    form.getFieldValue('storageType')
                                )?.name
                            }
                        </Col>
                    )}

                {form.getFieldValue('volumeType') && (
                    <Col
                        span={24}
                        className={styles.WizardFormConfigurationStep5Col}
                    >
                        <strong>Storage type:</strong>{' '}
                        {form.getFieldValue('volumeType')?.info} (
                        {
                            deploymentOptions?.getVolumeTypeInfo(
                                form.getFieldValue('cloudProvider'),
                                form.getFieldValue('volumeType')
                            )?.name
                        }
                        )
                    </Col>
                )}

                {form.getFieldValue('volumeSize') && (
                    <Col
                        span={24}
                        className={styles.WizardFormConfigurationStep5Col}
                    >
                        <strong>Storage size:</strong>{' '}
                        {form.getFieldValue('volumeSize')} GB
                    </Col>
                )}
                {form.getFieldValue('volumeIops') && (
                    <Col
                        span={24}
                        className={styles.WizardFormConfigurationStep5Col}
                    >
                        <strong>Volume IOPS:</strong>{' '}
                        {form.getFieldValue('volumeIops')}{' '}
                    </Col>
                )}
            </Row>
            {showNetwork && (
                <>
                    <Divider />

                    <p>
                        <strong>Network settings</strong>
                    </p>

                    <Row className={styles.WizardFormConfigurationStep5Row}>
                        <Col
                            span={24}
                            className={styles.WizardFormConfigurationStep5Col}
                        >
                            <strong>Network type:</strong>{' '}
                            {form.getFieldValue('network')?.name}
                        </Col>

                        {form.getFieldValue('availabilityZones')?.length && (
                            <Col
                                span={24}
                                className={
                                    styles.WizardFormConfigurationStep5Col
                                }
                            >
                                <strong>Availability zone:</strong>{' '}
                                {form.getFieldValue('networkType') === 'ha'
                                    ? 'Multi AZ'
                                    : 'Single AZ'}
                            </Col>
                        )}

                        {vpc && (
                            <Col
                                span={24}
                                className={
                                    styles.WizardFormConfigurationStep5Col
                                }
                            >
                                <strong>VPC:</strong>{' '}
                                {`${vpc?.getName()} - ${vpc?.getCidrIpv4Block()}`}
                            </Col>
                        )}

                        {(form.getFieldValue('availabilityZones')?.length ||
                            0) > 1 && (
                            <Col
                                span={24}
                                className={
                                    styles.WizardFormConfigurationStep5Col
                                }
                            >
                                <strong>Zones:</strong>
                                <div>
                                    {form
                                        .getFieldValue('availabilityZones')
                                        .map((az: any, index: number) => {
                                            return (
                                                <div>
                                                    Instance {index + 1} -{' '}
                                                    {deploymentOptions?.getAvailabilityZoneName(
                                                        cloudProvider,
                                                        form.getFieldValue(
                                                            'region'
                                                        ),
                                                        az
                                                    )}
                                                </div>
                                            );
                                        })}
                                </div>
                            </Col>
                        )}
                    </Row>
                </>
            )}
            {eulaEnabled && (
                <>
                    <Divider />
                    <p>
                        <strong>EULA</strong>
                    </p>
                    <Row className={styles.WizardFormConfigurationStep5Row}>
                        <Col
                            span={24}
                            className={styles.WizardFormConfigurationStep5Col}
                        >
                            <Checkbox
                                checked={eula}
                                onChange={handleEulaChange}
                            >
                                I have read and I hereby agree to the{' '}
                                <a
                                    href={EULALink}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    EULA
                                </a>
                            </Checkbox>
                        </Col>
                    </Row>
                </>
            )}
            <div className={styles.StepsExtraSmallScreen}>
                <StepsExtra />
            </div>
        </div>
    );
}
