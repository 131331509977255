import { Alert, Col, Form, InputNumber, Row, Select, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import { useEffect, useState } from 'react';
import DeploymentOptions from '../../../../types/DeploymentOptions';
import InstanceVolumeType from '../../../../types/InstanceVolumeType';
import InstanceVolumeSize from '../../../../types/InstanceVolumeSize';
import InstanceVolumeIopsValue from '../../../../types/InstanceVolumeIopsValue';
import { FormInstance } from 'antd/lib/form';
import styles from './InstanceVolumeTypeForm.module.less';
import DatabaseVendor from '../../../../types/DatabaseVendor';
import { useAppSelector } from '../../../../redux/hooks';
import { StorageItem } from '../InstanceSizeForm';

export type InstanceVolumeTypeFormProps = {
    form: FormInstance;
    selectedTech?: DatabaseVendor;
    deploymentOptions?: DeploymentOptions;

    // @todo remove this and handle change on this component, expose onVolumeTypeChange if it's required
    handleVolumeTypeChange: Function;
    // @todo user should be obtained from global state or context when it's implemented
    // @todo volumeSize, volumeType, volumeIops, enteredVolumeSize can be obtained from sate or context
    volumeSize?: InstanceVolumeSize;
    volumeType?: InstanceVolumeType;
    volumeTypes: InstanceVolumeType[];
    storageItems: StorageItem[];
    volumeIops?: InstanceVolumeIopsValue;
    enteredVolumeSize?: number;
    testId: string;
    onVolumeSizeChange?: (value?: number) => void;
    onIOPSChange?: (value?: number) => void;
};
export default function InstanceVolumeTypeForm({
    form,
    selectedTech,
    deploymentOptions,
    handleVolumeTypeChange,
    volumeSize,
    volumeType,
    volumeTypes,
    volumeIops,
    storageItems,
    enteredVolumeSize,
    testId = 'InstanceVolumeTypeForm',
    onVolumeSizeChange,
    onIOPSChange,
}: InstanceVolumeTypeFormProps) {
    const { isBackupRestore, restoreDatastore } = useAppSelector(
        (state) => state.restoreBackup
    );

    const [storageType, setStorageType] = useState<string>(
        form.getFieldValue('storageType')
    );

    const handleIopsValidation = (rule: any, value: any) => {
        if (!value || !enteredVolumeSize || !volumeIops) {
            // resolve to avoid showing validation errors
            return Promise.resolve();
        }

        if (enteredVolumeSize * volumeIops?.maxPerGb >= value) {
            return Promise.resolve();
        }

        return Promise.reject(
            `Max allowed IOPS is ${volumeIops?.maxPerGb} per GB of volume size.`
        );
    };

    useEffect(() => {
        if (restoreDatastore) {
            let restoreVolumeType = '';
            storageItems.forEach((s: StorageItem) => {
                if (
                    s.volumeTypes?.find(
                        (v) => v.code === restoreDatastore?.volumeType
                    )
                ) {
                    restoreVolumeType = s.code || '';
                }
            });

            setStorageType(
                restoreDatastore?.volumeType === ''
                    ? 'ephemeral'
                    : restoreVolumeType
            );
            form.setFieldsValue({
                storageType:
                    restoreDatastore?.volumeType === ''
                        ? 'ephemeral'
                        : restoreVolumeType,
                volumeIops: restoreDatastore?.volumeIops,
                volumeSize: restoreDatastore?.volumeSize,
            });
            onIOPSChange?.(restoreDatastore?.volumeIops);
        }
    }, [restoreDatastore, volumeTypes]);

    useEffect(() => {
        if (storageType === 'ephemeral') {
            form.setFieldsValue({
                volumeType: undefined,
                volumeSize: undefined,
                volumeIops: undefined,
            });
        }
    }, [storageType]);

    return (
        <div>
            {volumeTypes.length > 0 && (
                <div>
                    <p>
                        <strong>Volume type</strong>
                    </p>

                    {selectedTech?.isRedis() && (
                        <Alert
                            style={{ marginBottom: '1rem' }}
                            message="The allocated volume size for Redis is twice the amount of RAM."
                            icon={<InfoIcon />}
                            showIcon
                        />
                    )}

                    <Row>
                        <Col span={12}>
                            <Form.Item
                                name="volumeType"
                                label="Volume"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Select your preferred volume type',
                                    },
                                ]}
                            >
                                <Select
                                    optionFilterProp="children"
                                    placeholder="Select volume type"
                                    data-testid={`${testId}VolumeTypeSelect`}
                                >
                                    {volumeTypes.map(
                                        (t: InstanceVolumeType) => (
                                            <Select.Option
                                                key={t.code}
                                                value={t.code}
                                                onChange={
                                                    handleVolumeTypeChange
                                                }
                                            >
                                                <Row>
                                                    <Col>
                                                        <span
                                                            data-testid={`${testId}VolumeTypeSelectOption`}
                                                        >
                                                            {t.name}
                                                        </span>{' '}
                                                        {t.info && (
                                                            <InfoIcon
                                                                info={t.info}
                                                            />
                                                        )}
                                                    </Col>{' '}
                                                </Row>
                                            </Select.Option>
                                        )
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    {volumeSize && !selectedTech?.isRedis() && (
                        <>
                            <p>
                                <strong>Storage size</strong>
                            </p>
                            {deploymentOptions?.getVolumeSizesInfo() && (
                                <Alert
                                    className={
                                        styles.InstanceVolumeTypeFormAlertBox
                                    }
                                    message={deploymentOptions?.getVolumeSizesInfo()}
                                    showIcon
                                    type="info"
                                />
                            )}
                            <Form.Item
                                name="volumeSize"
                                label="Size"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select volume size',
                                    },
                                ]}
                                extra={
                                    <small>
                                        The desired value must be in the range
                                        of{' '}
                                        {isBackupRestore
                                            ? restoreDatastore?.volumeSize === 0
                                                ? volumeSize?.min
                                                : restoreDatastore?.volumeSize
                                            : volumeSize?.min}
                                        GB and {volumeSize?.max}GB
                                    </small>
                                }
                            >
                                <InputNumber
                                    min={
                                        isBackupRestore
                                            ? restoreDatastore?.volumeSize === 0
                                                ? volumeSize?.min
                                                : restoreDatastore?.volumeSize
                                            : volumeSize?.min
                                    }
                                    max={volumeSize?.max}
                                    className={
                                        styles.InstanceVolumeTypeFormInputNumber
                                    }
                                    placeholder="Enter volume size"
                                    size="large"
                                    formatter={(value?: number) => `${value}`}
                                    onChange={(value) => {
                                        onVolumeSizeChange?.(
                                            value || undefined
                                        );
                                    }}
                                    parser={
                                        ((value: string) =>
                                            value
                                                .replace(' ', '')
                                                .replace(
                                                    volumeSize.unit,
                                                    ''
                                                )) as any
                                    }
                                    data-testid={`${testId}VolumeSizeInput`}
                                />
                            </Form.Item>
                        </>
                    )}

                    {volumeType && volumeType.hasIops && volumeIops && (
                        <>
                            <p>
                                <strong>IOPS</strong>
                            </p>
                            <Form.Item
                                name="volumeIops"
                                label=""
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select IOPS',
                                    },
                                    {
                                        validator: handleIopsValidation,
                                    },
                                ]}
                            >
                                <InputNumber
                                    className={
                                        styles.InstanceVolumeTypeFormInputNumber
                                    }
                                    min={volumeIops?.min}
                                    max={volumeIops?.max}
                                    value={restoreDatastore?.volumeIops}
                                    disabled={isBackupRestore}
                                    onChange={(value) => {
                                        onIOPSChange?.(value || undefined);
                                    }}
                                    size="large"
                                    data-testid={`${testId}VolumeIopsInput`}
                                />
                            </Form.Item>
                        </>
                    )}
                </div>
            )}
        </div>
    );
}
