export interface OrganizationInterface {
    id: string;
    name: string;
}

export type OrganizationProps = {
    id: string;
    name: string;
};
export default class Organization implements OrganizationInterface {
    readonly id: string;
    readonly name: string;

    constructor(props: OrganizationProps) {
        this.id = props.id;
        this.name = props.name;
    }
}
